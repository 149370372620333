<template>
<footer class="bg-body-tertiary text-center text-lg-start">
  <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.05); margin-top: 4em;">
    © 2024 Copyright:
    <a class="text-body" href="https://s2studio.cz/" target="_blank">S2Studio</a>
  </div>
</footer>
</template>

<script>
export default {
  name: "TosFooter"
}
</script>

<style scoped>
</style>