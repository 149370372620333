<template>
  <div class="container">
    <div class="card myTopMargin">
      <div class="card-body row g-3">
        <div class="form-group row-g5">
          <div class="input-group">
            <span class="input-group-text bg-secondary" id="inputGrouID" style="color: white">Kalkulace číslo</span>
            <input v-if="calculation" type="text" class="form-control" id="cnum" name="cnum"
                   :value="calculation.number"
                   readonly="">
          </div>

        </div>
      </div>
    </div>
    <CalculationBasicInfo v-if="calculation"
                          :calculation-info="calculation.detail"
                          :calculation-name="calculation.name"
                          v-model:calculation-name="calculation.name"
                          v-model:calculation-info="calculation.detail"/>
    <CalculationItems v-if="calculation"
                      :cid="cid"
                      :list-calculation-items="calculation.items"
                      @item-update="loadCalculation"/>
     <CalculationIndividualItems :cid="cid"/>
    <CalculationTransport v-if="calculation"
                          :cid="calculation.uid"
                          :calculation-items="calculation.items"
                          :transportation="calculation.transportation"
                          v-model:transportation="calculation.transportation"/>
    <CalculationAssembly v-if="calculation"
                         :cid="calculation.uid"
                         :calculation-items="calculation.items"
                         :assembly="calculation.assembly"
                         v-model:assembly="calculation.assembly"/>
    <div class="row justify-content-end myTopMargin">
      <div class="col-auto">
        <button type="submit" class="btn btn-danger mx-1" @click="cancelButton">Zrušit</button>
        <button type="submit" class="btn btn-success mx-1" @click="saveButton">Uložit</button>
      </div>
    </div>
  </div>
</template>

<script>
import CalculationBasicInfo from "@/components/calculation/CalculationBasicInfo.vue";
import CalculationItems from "@/components/calculation/CalculationItems.vue";
import CalculationTransport from "@/components/calculation/CalculationTransport.vue";
import CalculationAssembly from "@/components/calculation/CalculationAssembly.vue";
import CalculationIndividualItems from "@/components/calculation/CalculationIndividualItems.vue";
import router from "@/router";
import transportation from "@/models/Transportation";

export default {
  name: "CalculationEditView",
  components: {
    CalculationBasicInfo,
    CalculationItems,
    CalculationTransport,
    CalculationAssembly,
    CalculationIndividualItems
  },
  props: ['cid'],
  data() {
    return {
      isCalculationLoaded: false,
      calculation: null
    }
  },
  computed: {
    // ...mapGetters('calculation', ['getCalculation']);
    // calculation() {
    //   return this.$store.getters['calculation/getCalculation']
    // }
  },
  methods: {
    transportation,
    cancelButton() {
      router.push({name: 'home'})

    },
    saveButton() {
      this.$store.dispatch('calculation/updateCalculation', this.calculation)
          .then(() => {
            router.push({name: 'home'})
          })
    },
    loadCalculation() {
      this.$store.dispatch('calculation/setCalculationById', this.cid)
          .then(() => {
            this.calculation = this.$store.getters['calculation/getCalculation']
          })
    }
  },
  watch: {
    // calculation: {
    //   handler(newVal) {
    //     console.log(newVal)
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {
    this.loadCalculation()
  }
}
</script>

<style scoped>
</style>