<template>
  <div class="container">
    <CalculationList />
  </div>
</template>

<script>
import CalculationList from "@/components/calculation/CalculationList.vue";

export default {
  name: 'HomeView',
  components: {
    // SideActions,
    // PieceActions,
    // MaterialSelector,
    CalculationList
  }
}
</script>
     