<template>
  <div class="container">
    <CalculationItemMaterialSelector v-if="priceListItemOrigin" :price-list-item-origin="priceListItemOrigin" :set-modal-visible="modalVisible"
                                     :cid="cid" @selected-material="setMaterial"/>
    <CalculationItemName :name="null" @update-name="setItemName"/>
    <CalculationItemDimensions v-if="dimensions && shape_id !== '1'" :item-sides="dimensions" :shape="shape_id" @dimension-change="setDimensions"/>
    <CalculationItemDimensionsSquare v-if="dimensions && shape_id === '1'" :item-sides="dimensions" @dimension-change="setDimensions"/>
    <div class="row justify-content-end myTopMargin">
      <div class="col-auto">
        <button type="button" class="btn btn-danger mx-1" @click="chancelButton">Zrušit</button>
        <button type="button" class="btn btn-success mx-1" @click="continueButton">Pokračovat v editaci</button>
      </div>
    </div>
  </div>
</template>

<script>
import CalculationItemMaterialSelector from "@/components/items/CalculationItemMaterialSelector.vue";
import CalculationItemDimensionsSquare from "@/components/items/CalculationItemDimensionsSquare.vue";
import CalculationItemDimensions from "@/components/items/CalculationItemDimensions.vue";
import CalculationItemName from "@/components/items/CalculationItemName.vue";
import myApi from "@/api/api";
import router from "@/router";
// import {mapGetters} from 'vuex'

export default {
  name: "CalculationItemNewView",
  components: {
    CalculationItemMaterialSelector,
    CalculationItemDimensions,
    CalculationItemDimensionsSquare,
    CalculationItemName
  },
  props: ['cid', 'shape_id'],
  data() {
    return {
      priceListItemOrigin: null,
      selectedDimensions: null,
      selectedMaterial: null,
      modalVisible: true,
      newItem: {
        calculationUid: this.cid,
        priceListItem: 1,
        shapeId: parseInt(this.shape_id),
        dimensions: null,
        name: null
      },
    }
  },
  computed: {
    dimensions() {
      let shape = this.$store.getters['items/geCalculationItemShapeById'](this.shape_id)
      if (shape) {
        let sides = shape.dimensions
        let response = {}
        for (let [sideName, visible] of Object.entries(sides)) {
          if (visible) {
            response[sideName] = null
          }
        }
        return response
      }
      return null
    }
  },
  methods: {
    chancelButton() {
      router.push({
        name: 'calculationEdit',
        query: {cid: this.cid}
      });
    },
    continueButton() {
      this.$store.commit('items/setCalculationItem', null)
      // update dimensions on shape id 1 - square / rectangle
      if (this.shape_id === '1') {
        let extend_dimensions = {
          c: this.newItem.dimensions['a'],
          d: this.newItem.dimensions['b']
        }
        Object.assign(this.newItem.dimensions, extend_dimensions)
      }
      this.$store.dispatch('items/addNewItem', this.newItem)
      setTimeout(() => {
        let newItemLoaded = this.$store.getters['items/getCalculationItem'];
        router.push({
          name: 'calculationItemEdit',
          query: {cid: this.cid, item: newItemLoaded.id, update: false}
        })
      }, 500)
    },
    setMaterial(n) {
      // console.log(n)
      this.newItem.priceListItem = n.materialId;
    },
    setDimensions(n) {
      this.newItem.dimensions = n;
    },
    setItemName(n) {
      this.newItem.name = n
    }
  },
  async mounted() {
    const last_used = await myApi.lastUsedMaterial(this.cid);
    if (last_used !== null) {
      const defaultMaterial = {
        pricelist: last_used.price_list_id,
        thickness: last_used.thickness_id,
        material: last_used.id
      }
      this.priceListItemOrigin = defaultMaterial;
      this.setMaterial({materialId: last_used.id});
      this.modalVisible = false;
    } else {
      this.priceListItemOrigin = {
        pricelist: 1,
        thickness: 1,
        material: 1
      }
    }
  }
}

</script>

<style scoped>
</style>