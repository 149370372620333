<template>
  <div class="card myTopMargin">
    <div class="card-header" id="basicInfoHeading">
      <div class="row my-2">
        <h3>Individuální položky</h3>
      </div>
    </div>
    <div class="card-body row g-3">
      <IndividualItemInsert :cid="cid"/>
      <IndividualItemList :cid="cid"/>
    </div>
  </div>

</template>

<script>
import IndividualItemInsert from "@/components/calculation/IndividualItemInsert.vue";
import IndividualItemList from "@/components/calculation/IndividualItemList.vue";

export default {
  name: "CalculationIndividualItems",
  props: {
    cid: {
      type: String,
      require: true,
    }
  },
  components: {
    IndividualItemInsert,
    IndividualItemList
  }
}
</script>


<style scoped>
</style>