<template>
  <div class="card myTopMargin">
    <div class="card-header">
      <h4>Rozměry (mm):</h4>
    </div>
    <div class="card-body">
      <div class="row gy-3">
        <div class="col col-md-7">
          <div class="row gy-3">
            <div class="col-md-6" v-for="(value, key) in itemSidesInt" :key="key">
              <div class="input-group">
                <span class="input-group-text" :id="'side_' + key + '_header'">Hrana {{ key.toUpperCase() }}</span>
                <input type="number" class="form-control" step="1" min="1" :aria-label="'side_'+ key"
                       :aria-describedby="'side_' + key + '_header'" placeholder="Zadejte rozměry" :id="'side_'+ key"
                       :name="key"
                       :value="value" @focus="highlightSide(key.toUpperCase())" @blur="removeHighlight(key.toUpperCase())"
                       @input="updateSideValue"
                       required>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex col-md-5 bg-light order-first order-md-last justify-content-center">
          <div ref="svgContainer" v-html="svgContent" class="d-flex image_responsive"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import triangle from "@/assets/triangle_item.svg";
import l_shape from "@/assets/l_item.svg";
import u_shape from "@/assets/u_item.svg";
import quard_shape from "@/assets/quard_item.svg";
import circle from "@/assets/circle_item.svg";
import ellipse from "@/assets/ellipse_item.svg";

export default {
  name: "CalculationItemDimensions",
  emits: ['dimension-change'],
  props: ['itemSides', 'shape'],
  data() {
    return {
      itemSidesInt: this.itemSides,
      svgContent: '',
      shape_img_mapper: {
        1: quard_shape,
        2: l_shape,
        3: u_shape,
        4: triangle,
        5: circle,
        6: ellipse
      },
    };
  },
  methods: {
    async loadSVG() {
      const response = await axios.get(this.shape_img_mapper[this.shape]);
      this.svgContent = response.data;
    },
    updateSideValue(event) {
      this.itemSidesInt[event.target.id.slice(5)] = parseInt(event.target.value);
      this.$emit('dimension-change', this.itemSidesInt);
    },
    highlightSide(side) {
      this.removeHighlight();
      const textElement = this.$refs.svgContainer.querySelector(`#text${side}`);
      if (textElement) {
        textElement.setAttribute('style', 'font-weight: bold; fill: red; font-size: 2em;');
      }
    },
    removeHighlight() {
      const highlightedElements = this.$refs.svgContainer.querySelectorAll('text[style]');
      highlightedElements.forEach(element => {
        element.removeAttribute('style');
      });
    }
  },
  async mounted() {
    await this.loadSVG();
  }
};
</script>

<style scoped>
.image_responsive {
  max-width: 100%;
  height: auto; /* zachování proporcí */
  display: block;
  color: firebrick;
}
</style>