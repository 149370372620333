<template>
  <div class="card myTopMargin">
    <div class="card-header">
      <h4>Impregnace dílu</h4>
    </div>
    <div class="card-body">
        <div class="form-check">
          <input class="form-check-input"
                 type="checkbox"
                 value="1"
                 id="impregnation"
                 name="impregnation"
                 @change="toggleSelected"
                 :checked="checked"
                 >
          <label class="form-check-label" for="impregnation">
            Impregnace dílu
          </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculationItemImpregnation",
  emits: ["selected-items"],
  props: {
    itemActions: Object,
  },
  data() {
    return {
      selectedImpregnation: null,
      checked: false
    }
  },
  computed: {
    impregnationAction() {
      const response = this.$store.getters['items/getCalculationItemActions'];
      return response ? response.surface[0] : null
    }
  },
  methods: {
    toggleSelected() {
      if (this.selectedImpregnation) {
        this.selectedImpregnation = null
      } else {
        const response = {
          [this.impregnationAction.id] : {
            related_to: this.impregnationAction.related_to,
            sides: null,
            unit_count: 1
          }
        }
        this.selectedImpregnation = response
      }
    }
  },
  watch: {
    selectedImpregnation: {
      handler(newVal) {
        this.$emit('selected-items', newVal)
      },
      deep: true,
      immediate: true
    },
    itemActions: {
      handler(newVal) {
        if (newVal) {
          for (const [key, value] of Object.entries(newVal)) {
            if (value.related_to === 'surface') {
              this.selectedImpregnation = {[key] : value};
              this.checked = true;
            }
          }
        }
      },
      deep: true,
      immediate: true
    },


  },
  created() {
  }
}
</script>

<style scoped>
</style>