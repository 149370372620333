export function formatFloat(number, decimalPlaces) {
    // if (typeof number !== 'number' || typeof decimalPlaces !== 'number') {
    //     throw new TypeError('Both arguments must be numbers');
    // }

    if (number !== undefined) {
        return number.toLocaleString('cs-CZ', {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    });
    } else {
        return null
    }

}
