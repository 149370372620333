<template>
  <div class="row my-3">
    <div class="form-group col-md-8">
      <label for="customItemName">Název:</label>
      <input type="text" class="form-control" id="customItemName" name="customItemName" v-model="itemName">
    </div>
    <div class="form-group col-md-1">
      <label for="customItemUnitCount">Počet:</label>
      <input type="text" class="form-control" id="customItemUnitCount" name="customItemUnitCount" v-model="itemUnitCount">
    </div>
    <div class="form-group col-md-2">
      <label for="customItemUnitPrice">Cena za jednotku:</label>
      <input type="text" class="form-control" id="customItemUnitPrice" name="customItemUnitPrice" v-model="itemUnitPrice">
    </div>
    <div class="form-group col-md-1 align-self-end">
      <a class="btn btn-primary mx-1" :class="allowButton" role="button" @click="setNewItem">Vložit</a>
    </div>
  </div>
</template>

<script>
import myApi from "@/api/api";

export default {
  name: "IndividualItemInsert",
  props: {
    cid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      itemName: null,
      itemUnitCount: null,
      itemUnitPrice: null,
    }
  },
  computed: {
    allowButton() {
      return !!this.itemName && !!this.itemUnitCount && !!this.itemUnitPrice ? "" : "disabled"
    }
  },
  methods: {
    async setNewItem() {
      console.log("executed");
      const result = await myApi.InsertIndividualItems({calculation_uid: this.cid, name: this.itemName, unit_count: this.itemUnitCount, unit_price: this.itemUnitPrice})
      let resBoolean = !!result
      if (resBoolean) {
        this.$store.dispatch("calculation/individualItemList", this.cid);
      }
      this.itemName = null;
      this.itemUnitCount = null;
      this.itemUnitPrice = null;
    }
  }
}
</script>


<style scoped>

</style>