import CalculationItem from "@/models/CalculationItem";
import CalculationDetail from "@/models/CalculationDetail";
import Transportation from "@/models/Transportation";
import Assembly from "@/models/Assembly";
import IndividualItem from "@/models/IndividualItem";

// constructor for Calculation object from database object

function Calculation(calc) {
  // console.log(calc)
  let calculationOrigin = calc;
  let item_list = [];
  let individual_item_list = []
  this.uid = calc.uid;
  this.name = calc.name;
  this.number = calc.number;
  this.pdfFile = calc.pdf_file;
  this.status = calc.status;
  this.detail = new CalculationDetail(calc.detail);
  if (calc.items.length > 0) {
    calc.items.forEach((currentValue) => {
      let item = new CalculationItem(currentValue);
      item_list.push(item);
    });
  }
  this.items = item_list;
  this.assembly = calc.assembly ? new Assembly(calc.assembly) : null;
  this.transportation = calc.transportation ? new Transportation(calc.transportation) : null;

  if (calc.individual_items && calc.individual_items.length > 0) {
    calc.individual_items.forEach((currentValue) => {
      let item = new IndividualItem(currentValue);
      individual_item_list.push(item);
    })
  }
  this.individualItems = individual_item_list;

  // added itemIdList in assembly and assembly
  if (this.assembly && this.items) {
    let itemList = [];
    this.items.forEach((item) => {
      if (item.calculationAssemblyId) {
        itemList.push(item.id)
      }
    })
    this.assembly.itemList = itemList
  }

  // added itemIdList in assembly and transportation
  if (this.transportation && this.items) {
    let itemList = [];
    this.items.forEach((item) => {
      if (item.calculationTransportationsId) {
        itemList.push(item.id)
      }
    })
    this.transportation.itemList = itemList
  }

  Object.defineProperty(this, 'calculationOrigin', {
    get: () => {
      return calculationOrigin
    }
  })
}

export default Calculation;