<template>
  <LoginForm />
</template>

<script>
import LoginForm from "@/components/auth/LoginForm.vue";

export default {
  name: "LoginView",
  components: {
    LoginForm
  },
  methods: {
  }
}
</script>

<style scoped>
</style>