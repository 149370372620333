import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CalculationEditView from '../views/CalculationEditView.vue'
import CalculationItemEditView from "@/views/CalculationItemEditView.vue";
import CalculationItemNewView from "@/views/CalculationItemNewView.vue";
import CalculationCalculateView from "@/views/CalculationCalculateView.vue";
import LoginView from "@/views/LoginView.vue";
import PageNotFoundView from "@/views/PageNotFoundView.vue";
import AdminUnitsView from "@/views/admin/AdminUnitsView.vue";
import AdminMaterialsView from "@/views/admin/AdminMaterialsView.vue";
import AdminThicknessView from "@/views/admin/AdminThicknessView.vue";
import AdminPriceListView from "@/views/admin/AdminPriceListView.vue";
import AdminActionsView from "@/views/admin/AdminActionsView.vue";
import AdminUsersView from "@/views/admin/AdminUsersView.vue";
import AdminExportsView from "@/views/admin/AdminExportsView.vue";
import AboutView from "@/views/AboutView.vue";
import PasswordChangeView from "@/views/PasswordChangeView.vue";
import store from "@/store";
// import VueCookie from "vue-cookie";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit',
    name: 'calculationEdit',
    component: CalculationEditView,
    props: route => ({cid: route.query.cid}),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculation/item-edit',
    name: 'calculationItemEdit',
    component: CalculationItemEditView,
    props: route => ({cid: route.query.cid, item_id: route.query.item, do_update: route.query.update}),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculation/item-new',
    name: 'calculationItemNew',
    component: CalculationItemNewView,
    props: route => ({cid: route.query.cid, shape_id: route.query.shape}),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculation/calculate',
    name: 'calculationCalculate',
    component: CalculationCalculateView,
    props: route => ({cid: route.query.cid}),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'loginView',
    component: LoginView,
    meta: {
      requiresUnAuth: true
    }
  },
  {
    path: '/password',
    name: 'passwordChangeView',
    component: PasswordChangeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/units',
    name: 'adminUnitsView',
    component: AdminUnitsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/materials',
    name: 'adminMaterialsView',
    component: AdminMaterialsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/thickness',
    name: 'adminThicknessView',
    component: AdminThicknessView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/pricelist',
    name: 'adminPriceListView',
    component: AdminPriceListView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/actions',
    name: 'adminActionsView',
    component: AdminActionsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/users',
    name: 'adminUsersView',
    component: AdminUsersView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/exports',
    name: 'adminExportsView',
    component: AdminExportsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    component: AboutView,
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFoundView
  }
]

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'active',
  routes
})

router.beforeEach((to, from, next) => {
  let autoLoginProcess = store.getters['getDoingAutoLogin']

  if (autoLoginProcess instanceof Promise) {
    autoLoginProcess
      .then(() => {
        const isAuthenticated = store.getters.getIsAuthenticated
        if (to.meta.requiresAuth && !isAuthenticated) {
          next('/login');
        } else if (to.meta.requiresUnAuth && isAuthenticated) {
          next('/');
        } else {
          next();
        }
      })
  } else {
    const isAuthenticated = store.getters.getIsAuthenticated
    if (to.meta.requiresAuth && !isAuthenticated) {
      next('/login');
    } else if (to.meta.requiresUnAuth && isAuthenticated) {
      next('/');
    } else {
      next();
    }
  }
})

export default router
