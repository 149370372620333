<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <!-- Container wrapper -->
    <div class="container">
      <a class="navbar-brand" href="#" @click="toHome">ToS</a>
      <!-- Toggle button -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Collapsible wrapper -->
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <!-- Left links -->
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" href="#" @click="toHome">Kalkulace</a>
          </li>
          <li v-if="isAdmin" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"
               @click="toAdmin">Admin</a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="toAdminUnits">Jednotky</a></li>
              <li><a class="dropdown-item" href="#" @click="toAdminMaterials">Materiály</a></li>
              <li><a class="dropdown-item" href="#" @click="toAdminThickness">Tloušťky materiálů</a></li>
              <li><a class="dropdown-item" href="#" @click="toAdminPriceList">Ceníky</a></li>
              <li><a class="dropdown-item" href="#" @click="toAdminActions">Obrábění</a></li>
              <li><a class="dropdown-item" href="#" @click="toAdminUsers">Uživatelé</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item" href="#" @click="toAdminExports">Exporty</a></li>
            </ul>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="toAbout">About</a>
          </li>
        </ul>
        <ul v-if="userShow" class="navbar-nav d-flex flex-row me-1">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">{{ userFullName }}
            <i class="bi bi-person" style="font-size: 1.5em;"></i>
            </a>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" @click="logout">Odhlásit se</a></li>
              <li><a class="dropdown-item" href="#" @click="toChangePassword">Změna hesla</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import router from "@/router";

export default {
  name: "NavBar",
  data() {
    return {
      userShow: false,
      userFullName: "",
      isAdmin: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['getUser']
    },
    isAuth() {
      return this.$store.getters['getIsAuthenticated']
    },
    fullName() {
      const user = this.$store.getters['getUser']
      if (user) {
        return `${user.last_name} ${user.first_name}`
      } else {
        return ""
      }
    }
  },
  methods: {
    toHome() {
      router.push({name: 'home'});
    },
    toAbout() {
      router.push({name: 'about'});
    },
    toAdminUnits() {
      router.push({name: 'adminUnitsView'});
    },
    toAdminMaterials() {
      router.push({name: 'adminMaterialsView'});
    },
    toAdminThickness() {
      router.push({name: 'adminThicknessView'});
    },
    toAdminPriceList() {
      router.push({name: 'adminPriceListView'});
    },
    toAdminActions() {
      router.push({name: 'adminActionsView'});
    },
    toAdminUsers() {
      router.push({name: 'adminUsersView'});
    },
    toAdminExports() {
      router.push({name: 'adminExportsView'});
    },
    logout() {
      this.$store.dispatch("logoutUser")
          .then(() => router.replace("/login"))
    },
    toChangePassword() {
      console.log("executed");
      router.push({name: 'passwordChangeView'});
    }
  },
  watch: {
    isAuth(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.userShow = newVal
      }
    },
    fullName(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.userFullName = newVal
      }
    },
    user: {
      handler(newVal, oldVal) {
        if (newVal === oldVal) {
          return null
        } else if (newVal && newVal.is_superuser) {
          this.isAdmin = true
        } else {
          this.isAdmin = false
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>


<style scoped>
</style>