<template>
  <div class="card myTopMargin">
    <div class="card-header">
      <h4>Volba materiálu:</h4>
    </div>
    <div class="card-body">
      <div class="row g-3">
        <div v-if="!isMaterialLoaded" class="row g-3 context-justify-center">
          <h3 class="text-black-50">Price list data is loading...</h3>
        </div>
        <div v-if="isMaterialLoaded" class="row g-3">
          <div class="form-group col-md-4">
            <label for="price_list" class="form-label">Ceník:</label>
            <select v-model="pricelist" class="form-select" id="price_list" name="price_list"
                    @change="pricelistChanged" disabled>
              <option v-for="(item, key) in materialList" :key="key" :value="key"> {{ item.name }}</option>
            </select>
          </div>

          <div class="form-group col-md-2">
            <label for="thickness" class="form-label">Síla materiálu:</label>
            <select v-model="thicknessid" class="form-select" id="thickness" name="thickness" disabled>
              <option v-for="(item, key) in materialList[pricelist].thickness" :key="key" :value="key">
                {{ item.name }}
              </option>
            </select>
          </div>

          <div class="form-group col-md-6">
            <label for="material" class="form-label">Materiál:</label>
            <select v-model="materialid" class="form-select" id="material" name="material" disabled>
              <option v-for="(item, key) in materialList[pricelist].thickness[thicknessid].materials" :key="key"
                      :value="key">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row g-3" v-if="!quickSelectOnce">
          <already-used-material-selector ref="materialSelector" :cid="cid" v-model="quickSelectedMaterial"/>
        </div>
        <div v-if="isMaterialLoaded" class="row g-3" style="margin-top: 1em;">
          <button @click="setMaterialModalVisible()"
                  id="btnMaterialChange"
                  class="btn btn-outline-dark">Změnit materiál
          </button>
        </div>
      </div>
    </div>
  </div>
  <CalculationItemMaterialModal :is-visible="materialModalVisible && isMaterialLoaded" :cid="cid"
                                @modal-status="modalStatus" @selected-material="setSelectedMaterial"/>

</template>
<script>
import CalculationItemMaterialModal from "@/components/items/CalculationItemMaterialModal.vue";
import AlreadyUsedMaterialSelector from "@/components/items/AlreadyUsedMaterialSelector.vue";
import myApi from "@/api/api";

export default {
  name: 'MaterialSelector',
  components: {
    CalculationItemMaterialModal,
    AlreadyUsedMaterialSelector,
  },
  emits: ['selected-material'],
  props: {
    priceListItemOrigin: Object,
    setModalVisible: {
      type: Boolean,
      required: false,
      default: false
    },
    cid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      materialModalVisible: false,
      warningBeforeSave: false,
      isMaterialChanged: false,
      pricelist: this.priceListItemOrigin.pricelist,
      thicknessid: this.priceListItemOrigin.thickness,
      materialid: this.priceListItemOrigin.material,
      quickSelectedMaterial: null,
      quickSelectOnce: false,
    }
  },
  computed: {
    materialList() {
      return this.$store.getters['calculation/getMaterialList']
    },
    isMaterialLoaded() {
      return this.materialList !== null;
    }
  },
  methods: {
    pricelistChanged() {
      this.setMaterialChanged()
    },
    setMaterialChanged() {
      this.isMaterialChanged = true
    },
    setMaterialModalVisible() {
      this.materialModalVisible = true
    },
    modalStatus(n) {
      if (n === 'closed' || n === 'saved') {
        this.materialModalVisible = false
      }
    },
    async setSelectedMaterial(n) {
      this.pricelist = n.priceListId;
      this.thicknessid = n.thicknessId;
      this.materialid = n.materialId;
      this.$emit('selected-material', n);
    }
  },
  watch: {
    quickSelectedMaterial: {
      async handler(newVal) {
        if (newVal) {
          const result = await myApi.getPriceListItem(newVal)
          this.setSelectedMaterial({
            priceListId: result.price_list_id,
            thicknessId: result.thickness_id,
            materialId: result.id
          })
          this.quickSelectOnce = true;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // this.$store.dispatch('calculation/loadMaterialList')
  },
  mounted() {
    if (this.setModalVisible) {
      this.materialModalVisible = true
    }
  }
}
</script>

<style scoped>
</style>