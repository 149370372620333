function CalculationDetail(detail) {
  if (detail) {
    this.id = detail.id;
    this.calculationUid = detail.calculation_uid;
    this.customer = detail.customer;
    this.street = detail.street;
    this.city = detail.city;
    this.zipcode = detail.zipcode;
    this.ico = detail.ico;
    this.dic = detail.dic;
    this.phone = detail.phone;
    this.email = detail.email;
  } else {
    this.id = null;
    this.calculationUid = null;
    this.customer = "";
    this.street = "";
    this.city = "";
    this.zipcode = "";
    this.ico = "";
    this.dic = "";
    this.phone = "";
    this.email = "";
  }
}

export default CalculationDetail;