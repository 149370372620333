<template>
  <div class="row justify-content-between">
    <div class="col-auto my-5">
      <h1>Seznam kalkulací</h1>
    </div>
    <div class="col-auto my-5">
      <button class="btn btn-outline-success btn-lg" @click="calculationNew">
        <i class="bi-plus-circle-dotted"></i>
        <strong>&nbsp;&nbsp;Nová kalkulace</strong>

      </button>
    </div>
  </div>
  <table class="table table-light table-striped table-hover">
    <thead class="table-head">
    <tr>
      <th>Název</th>
      <th>Číslo</th>
      <th>Datum vytvoření</th>
      <th>Status</th>
      <th>Akce</th>
    </tr>
    </thead>
    <tbody>
    <tr class="align-middle" v-for="(item, key) in calculationList" :key="key">
      <td> {{ item.name }}</td>
      <td> {{ item.number }}</td>
      <td> {{ item.created_at }}</td>
      <td> {{ item.status }}</td>
      <td>
        <div class="btn-group" role="group">
          <a class="btn btn-primary" @click="calculationEdit(item.uid)">Editovat</a>
          <a class="btn btn-warning" @click="calculationCopy(item.uid)">Vytvoř kopii</a>
          <a class="btn btn-danger" @click="calculationDelete(item.uid)">Smazat</a>
        </div>
        <a class="btn btn-success mx-3" @click="calculationCalculate(item.uid)">Spočítej</a>
        <a v-if="!!item.pdf_file" class="btn btn-info" :href="calculationHRefs(item.uid).pdf" target="_blank">PDF</a>

      </td>
    </tr>
    </tbody>
  </table>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end">
      <li class="page-item disabled"><a class="page-link" href="#">Previous</a></li>
      <li class="page-item active"><a class="page-link" href="#">1</a></li>
      <li class="page-item disabled"><a class="page-link" href="#">Next</a></li>
    </ul>
  </nav>
</template>

<script>
import router from "@/router";

export default {
  name: "CalculationList",
  data() {
    return {
      calculationList: [],
    }
  },
  methods: {
    listCalculation() {
      this.isMaterialLoaded = false
      this.$store.dispatch('calculation/listCalculation')
          .then((result) => {
            this.isMaterialLoaded = true
            this.calculationList = result
          })
    },
    calculationHRefs(cid) {
      const response = {};
      response["edit"] = '/edit?cid=' + cid
      response['copy'] = '/copy?cid=' + cid
      response['delete'] = '/delete?cid=' + cid
      response['calculate'] = '/calculate?cid=' + cid
      response['pdf'] = this.URL_API_BASE + '/download?cid=' + cid
      return response
    },
    calculationEdit(uid) {
      router.push({
        name: 'calculationEdit',
        query: {cid: uid}
      })
    },
    calculationCopy(uid) {
      this.$store.dispatch('calculation/copyCalculation', uid)
          .then((result) => {
            if (result) {
              this.listCalculation();
            }
          })
    },
    calculationDelete(uid) {
      this.$store.dispatch('calculation/deleteCalculation', uid)
          .then((result) => {
            if (result.status) {
              this.listCalculation();
            }
          })
    },
    calculationCalculate(uid) {
      router.push({
        name: 'calculationCalculate',
        query: {cid: uid}
      })
    },
    calculationNew() {
      this.$store.dispatch('calculation/createNewCalculation')
          .then((result) => {
            router.push({
              name: 'calculationEdit',
              query: {cid: result.uid}
            })
          })
    }
  },
  computed: {},
  mounted() {
    this.listCalculation()
  }
}
</script>

<style scoped>
</style>