import myAxiosBackend from "@/axios";
import CalculationItem from "@/models/CalculationItem";
import myApi from "@/api/api";

const CalculationItemsStore = {
  namespaced: true,
  state() {
    return {
      item: null,
      itemActions: null,
      itemNormalizedForUpdate: null,
      itemShapeList: null,
    }
  },
  mutations: {
    setCalculationItem(state, payload) {
      state.item = payload
    },
    setCalculationItemActions(state, payload) {
      state.itemActions = payload
    },
    setCalculationItemActionsNormalized(state, payload) {
      let surface_actions = [];
      let side_actions = [];
      let piece_actions = [];
      if (payload !== null) {
        for (let i = 0; i < payload.length; i++) {
          payload[i].related_to === 'surface' ? surface_actions.push(payload[i]) : null;
          payload[i].related_to === 'side' ? side_actions.push(payload[i]) : null;
          payload[i].related_to === 'piece' ? piece_actions.push(payload[i]) : null;
        }
        state.itemActions = {
          surface: surface_actions,
          side: side_actions,
          piece: piece_actions
        }
      } else {
        state.itemActions = null
      }
    },
    setItemList(state, payload) {
      state.itemShapes = payload
    },
    createNomalizedItemFromDB(state, payload) {
      state.itemNormalizedForUpdate = new CalculationItem(payload)
    },
    updateItemDimensions(state, payload) {
      if (state.itemNormalizedForUpdate) {
        state.itemNormalizedForUpdate.dimensions = payload
      }
    },
    updateItemName(state, payload) {
      if (state.itemNormalizedForUpdate) {
        state.itemNormalizedForUpdate.name = payload
      }
    },
    updateItemActions(state, payload) {
      if (state.itemNormalizedForUpdate) {
        state.itemNormalizedForUpdate.actions = payload
      }
    },
    setShapeList(state, payload) {
      state.itemShapeList = payload
    }
  },
  actions: {
    loadCalculationItem(context, itemId) {
      return myAxiosBackend.get('/api/v1/get_calculation_item_by_id', {params: {item_id: itemId}})
        .then((response) => {
          if (response.statusText === 'OK') {
            context.commit('setCalculationItem', response.data);
            context.commit('createNomalizedItemFromDB', response.data);
            return response.data
          } else {
            context.commit('setCalculationItem', null);
            return null;

          }
        })
    },
    loadItemActionsNormalized(context, payload) {
      myAxiosBackend.get('/api/v1/list_action_normalized_filtered', {params: payload}).then(response => response.statusText === 'OK' ?
        context.commit('setCalculationItemActionsNormalized', response.data) : context.commit('setCalculationItemActionsNormalized', null)
      )
    },
    updateItemMaterial(context, payload) {
      const url = new URL('/api/v1/update_calculation_item_material', myAxiosBackend.defaults.baseURL);
      url.search = new URLSearchParams(payload);
      return myAxiosBackend.post(url.toString())
        .then((response) => {
          if (response.statusText === 'OK') {
            context.commit('setCalculationItem', response.data);
            return response.data;
          } else {
            context.commit('setCalculationItem', null);
            return null;
          }
        })
    },
    updateItem(context, payload) {
      return myAxiosBackend.post('/api/v1/update_calculation_item', payload)
        .then((response) => {
          if (response.statusText === 'OK') {
            context.commit('setCalculationItem', response.data)
            return response.data
          } else {
            context.commit('setCalculationItem', null)
          }
        })
    },
    addNewItem(context, payload) {
      myAxiosBackend.post('/api/v1/add_calculation_item', payload)
        .then(response => response.statusText === 'OK' ?
          (context.commit('setCalculationItem', response.data), context.commit('createNomalizedItemFromDB', response.data)) : context.commit('setCalculationItemActionsNormalized', null))
    },
    deleteItem(context, payload) {
      const url = new URL('/api/v1/delete_calculation_item', myAxiosBackend.defaults.baseURL);
      url.search = new URLSearchParams(payload);
      return myAxiosBackend.delete(url.toString())
        .then((response) => {
          if (response.statusText === 'OK') {
            return response.data
          } else {
            return null
          }
        })
    },
    copyItem(context, payload) {
      const url = new URL('/api/v1/copy_calculation_item', myAxiosBackend.defaults.baseURL);
      url.search = new URLSearchParams(payload);
      return myAxiosBackend.post(url.toString())
        .then((response) => {
          if (response.statusText === 'OK') {
            return response.data
          } else {
            return null
          }
        })
    },
    async loadItemShapes(context) {
      const shapesList = await myApi.listShapes()
      shapesList ? context.commit('setShapeList', shapesList) : context.commit('setShapeList', null)
    }
  },
  getters: {
    getCalculationItem(state) {
      return state.item
    },
    getCalculationItemActions(state) {
      return state.itemActions
    },
    getCalculationItemShapes(state) {
      return state.itemShapeList
    },
    getNormalizedItem(state) {
      return state.itemNormalizedForUpdate
    },
    getItemDimensions(state) {
      return state.itemNormalizedForUpdate.dimensions
    },
    geCalculationItemShapeById: (state) => (id) => {
      if (state.itemShapeList) {
        return state.itemShapeList[id]
      }
      return null
    }
  }
}

export default CalculationItemsStore;