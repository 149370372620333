<template>
  <div class="form-group">
    <div class="row gy-3">
      <h5>Přidej nový dílec:</h5>
    </div>

    <button v-for="(item, key) in shapeList" :key="key"
            type="submit"
            :id="key"
            :formaction="createSubmitLink(cid, key)"
            class="btn btn-primary float-right mx-1"
            @click="addNewItem"> {{ item.name }}
    </button>
  </div>
</template>
<script>
import router from "@/router";

export default {
  name: "NewItemButtons",
  props: ['cid'],
  data() {
    return {}
  },
  computed: {
    shapeList() {
      return this.$store.getters['items/getCalculationItemShapes']
    }
  },
  methods: {
    createSubmitLink(cid, itemid) {
      let response = new URL('/calculation/item-edit', this.URL_BASE);
      response.searchParams.append('cid', cid);
      response.searchParams.append('item', itemid);
      return response
    },
    addNewItem(event) {
      let calculation = this.$store.getters['calculation/getCalculation']
      this.$store.dispatch('calculation/updateCalculation', calculation)
          .then(() => {
            router.push({
              name: 'calculationItemNew',
              query: {cid: this.cid, shape: event.target.id}
            })
          })

    }
  },
  mounted() {
    // this.$store.dispatch('items/loadItemShapes');
  }
}
</script>

<style scoped>
</style>