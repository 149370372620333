<template>
  <div class="card myTopMargin">
    <div class="card-header" id="assemblyHeading">
      <div class="row align-items-center my-2">
        <div class="col-auto">
          <div class="form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="assemblyAllowedSwitch"
                   name="assembly-allowed" @click="toggleVisibility()" v-model="isVisible">
          </div>
        </div>
        <div class="col-auto">
          <h3>Montáž</h3>
        </div>

      </div>
    </div>
    <div class="card-body">
      <fieldset class="row g-3" id="assemblyAllowedField" v-show="isVisible">
        <div class="row gy-2">
          <div class="form-group">
            <h5>Zvol dílce určené pro montáž:</h5>
            <CalculationItemsSelector :calculationItems="calculationItems"
                                      :caption="'assembly'"
                                      :selected-items="assemblyInternal.itemList" />
          </div>
        </div>
        <div class="row gy-2">
          <div class="form-group col-sm-12 col-md-3" aria-disabled="false">
            <label for="assembly-head-count">Počet osob:</label>
            <input type="number" step="1" min="1" class="form-control" id="assembly-head-count"
                   name="assembly-head-count" v-model="assemblyInternal.headCount">
          </div>
          <div class="form-group col-sm-12 col-md-3" aria-disabled="false">
            <label for="assembly-hours">Počet hodin:</label>
            <input type="number" step="1" min="1" class="form-control" id="assembly-hours" name="assembly-hours"
                   v-model="assemblyInternal.assemblyHours">
          </div>

        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
import Assembly from "@/models/Assembly";
import CalculationItemsSelector from "@/components/items/CalculationItemsSelector.vue";

export default {
  name: "CalculationAssembly",
  components: {
    CalculationItemsSelector
  },
  props: ['calculationItems', 'assembly', 'cid'],
  emits: ['update:assembly'],
  data() {
    return {
      isVisible: false,
      assemblyInternal: {},
    }
  },
  methods: {
    setVisibility(item) {
      this.isVisible = item !== null;
    },
    toggleVisibility() {
      this.isVisible = !this.isVisible
      if (this.isVisible) {
        this.$emit('update:assembly', this.assemblyInternal)
      } else {
        this.$emit('update:assembly', null)
      }
    },
    toggleAsseblyItems(item) {
      return !!item.calculationAssemblyId;
    }
  },
  watch: {
    assemblyInternal: {
      handler(newVal) {
        // console.log(newVal)
        if (this.isVisible) {
          this.$emit('update:assembly', newVal)
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.assemblyInternal = this.assembly ? this.assembly : new Assembly({
        calculation_uid: this.cid,
        assembly_hours: 0,
        head_count: 0,
        itemList: [],
      })
  },
  mounted() {
    this.setVisibility(this.assembly)
  }
}
</script>

<style scoped>
</style>