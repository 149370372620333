<template>
  <table class="table table-light table-striped table-hover" id="tbl-assembly-items">
    <thead class="table-head">
    <tr>
      <th>Zvol</th>
      <th>Název dílce</th>
      <th>Materiál</th>
      <th style="text-align: end;">Plocha</th>
      <th style="text-align: end;">Váha</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, key) in calculationItems" :key="key">
      <td>
        <div class="form-check">
          <input class="form-check-input" :checked="resolveSelection(item)" type="checkbox" :value="item.id" @change="setSelected"
                 :id="caption + '-item-' + item.id"
                 :name="caption + '-item-' + item.id">

        </div>
      </td>
      <td> {{ item.name }}</td>
      <td> {{ item.itemOrigin.price_list_item.name }}</td>
      <td style="text-align: end;"> {{ item.area }} m<sup>2</sup></td>
      <td style="text-align: end;"> {{ item.weight }} kg</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "CalculationItemsSelector",
  props: ['calculationItems', 'selectedItems', 'caption'],
  emits: ['update:selectedItems'],
  data() {
    return {
      selectedItemInternal: []
    }
  },
  methods: {
    resolveSelection(item) {
      return this.selectedItemInternal.includes(item.id);
    },
    setSelected(event) {
      const value = parseInt(event.target.value)
      const index = this.selectedItemInternal.indexOf(value)
      if (event.target.checked) {
        if (index < 0) {
          this.selectedItemInternal.push(value)
        }
      } else {
        if (index > -1) {
          this.selectedItemInternal.splice(index, 1)
        }
      }
      this.$emit('update:selectedItems', this.selectedItemInternal)
    }
  },
  created() {
    this.selectedItemInternal = this.selectedItems === undefined || this.selectedItems === null ?  [] : this.selectedItems
  }
}
</script>

<style scoped>
</style>