<template>
  <ModalBase title="Editace individuální položky:"
             :is-visible="isModalVisible"
             set-status-name="individualItemModalEdit"
             :save-allowed="true">
  </ModalBase>
</template>
<script>
import modalBase from "@/components/base/ModalBase.vue";

export default {
  name: "IndividualItemEditModal",
  components: {
    modalBase,
  },
  data() {
    return {
      isModalVisible: true,
    }
  }
}
</script>



<style scoped>

</style>