<template>
  <div class="card myTopMargin">
    <div class="card-header">
      <h4>Obrábění pro dílec:</h4>
    </div>
    <div class="card-body">
      <div class="row g-3">
        <table class="table table-light table-hover">
          <thead class="table-head">
          <tr>
            <th style="width: 5%;">Vyber</th>
            <th style="width: 20%;">Počet</th>
            <th style="width: 10%;">Jednotky</th>
            <th>Název</th>
          </tr>
          </thead>
          <tbody>
          <tr class="align-middle" v-for="item in pieceActions" :key="item.id">
            <PieceActionRow :item="item" @set-value="setPieceActionItem" @disable="removePieceActionItem"/>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import PieceActionRow from "@/components/items/PieceActionRow.vue";

export default {
  name: 'PieceActions',
  emits: ['selected-items'],
  components: {
    PieceActionRow
  },
  props: {
    materialId: Number,
    thicknessId: Number,
    itemActions: Array
  },
  data() {
    return {
      pieceActionSelectedItems: {},
      normalizedOriginalItemActions: null,
    }
  },
  computed: {
    pieceActions() {
      let response = this.$store.getters['items/getCalculationItemActions']
      if (response) {
        response.piece.forEach((currentValue) => {
          currentValue['checked'] = false;
          currentValue['unit_count'] = null;
          if (this.normalizedOriginalItemActions !== null && this.normalizedOriginalItemActions[currentValue.id] !== undefined) {
            currentValue['checked'] = true;
            currentValue['unit_count'] = this.normalizedOriginalItemActions[currentValue.id].unit_count
          }
        })
      }
      return response === null ? null : response.piece
    },
  },
  methods: {
    normalizedItemActions() {
      if (!this.normalizedOriginalItemActions) {
        let response = {}
        this.itemActions.forEach((currentValue) => {
          response[currentValue.actions_id] = currentValue
        })
        this.normalizedOriginalItemActions = response
      }
    },
    setPieceActionItem(n) {
      this.pieceActionSelectedItems[n.id] = n.value
    },
    removePieceActionItem(n) {
      delete this.pieceActionSelectedItems[n]
    }
  },
  watch: {
    pieceActionSelectedItems: {
      handler(newVal) {
        this.$emit('selected-items', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.normalizedItemActions()
  }
}
</script>

<style scoped>
</style>