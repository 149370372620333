<template>
  <div class="card myTopMargin">
        <div class="card-header">
          <h4>Jméno dílce:</h4>
        </div>
        <div class="card-body">
          <div class="form-group">
            <label for="name">Zadej název dílu:</label>
            <input type="text" class="form-control" id="name" name="name" :value="selectedName" required
                   @input="updateName">
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: "CalculationItemName",
  emits: ['update-name'],
  props: ['name'],
  data() {
    return {
      selectedName: null,
    }
  },
  methods: {
    updateName(event) {
      this.selectedName = event.target.value
      this.$emit('update-name', event.target.value)
    }
  },
  created() {
    this.selectedName = this.name
  }
}

</script>

<style scoped>
</style>