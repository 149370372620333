<template>
  <AutocompleteSelect v-if="usedMaterials"
      :options="usedMaterials"
      v-model="selectedItem"
      label="Rychlá volba / doposud použité materiály:"
      placeholder="zvol materiál..."
      id="already_used_materials"
  />
</template>

<script>
import AutocompleteSelect from "@/components/base/AutocompleteSelect.vue";
import myApi from "@/api/api";

export default {
  name: "AlreadyUsedMaterialSelector",
  expose: ['updateMaterialList'],
  components: {
    AutocompleteSelect,
  },
  props: {
    cid: {
      type: String,
      require: true
    }
  },
  data() {
    return {
      usedMaterials: null,
      selectedItem: null,
    }
  },
  methods: {
    async updateMaterialList() {
      const data = await myApi.allUsedMaterials(this.cid);
      const result = [];
      for (let i = 0; i < data.length; i++) {
        result.push({value: data[i].id, label: `${data[i].name}, ${data[i].surface}, ${data[i].thickness.name}, ${data[i].material.name}, ${data[i].price_list.name}`})
      }
      this.usedMaterials = result
    }
  },
  async mounted() {
    await this.updateMaterialList();
  }
}
</script>


<style scoped>

</style>