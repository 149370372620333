<template>
  <div class="card myTopMargin">
    <div class="card-header" id="calculationItemsHeading">
      <div class="row my-2">
        <h3>Zadání dílců pro výrobu</h3>
      </div>
    </div>
    <div class="card-body row g-3">
      <NewItemButtons :cid="cid"/>
      <div class="row gy-3">
        <h5 v-if="listCalculationItems.length > 0">Seznam dílů:</h5>
        <div class="row g-0">
          <CalculationItemDetailList v-for="(item, key) in listCalculationItems" :key="key" :item-detail="item"
                                     @item-update="itemsUpdated"/>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import CalculationItemDetailList from "@/components/items/CalculationItemDetailList.vue";
import NewItemButtons from "@/components/calculation/NewItemButtons.vue";

export default {
  name: "CalculationItems",
  emits: ['item-update'],
  components: {
    CalculationItemDetailList,
    NewItemButtons
  },
  props: ['cid', 'listCalculationItems'],
  methods: {
    itemsUpdated() {
      this.$emit('item-update')
    }
  }
}
</script>
<style scoped>

</style>