<template>
  <div class="card myTopMargin">
    <div class="card-header" id="basicInfoHeading">
      <div class="row my-2">
        <h3>Základní informace</h3>
      </div>
    </div>

    <div id="basicInfoCollapse">
      <div class="card-body row g-3">
        <div class="form-group col-md-12">
          <label for="name">Název kalkulace:</label>
          <input type="text" class="form-control" id="name" name="name" :value="calculationName"
                 @input="$emit('update:calculationName', $event.target.value)" required="">
        </div>
        <div class="form-group col-md-6">
          <label for="customer">Zákazník / firma:</label>
          <input type="text"
                 class="form-control"
                 id="customer"
                 name="customer"
                 v-model="calcInfoInternal.customer">
        </div>
        <div class="form-group col-md-3">
          <label for="ico">IČO:</label>
          <input type="text"
                 class="form-control"
                 id="ico" name="ico"
                 v-model="calcInfoInternal.ico">
        </div>
        <div class="form-group col-md-3">
          <label for="dic">DIČ:</label>
          <input type="text"
                 class="form-control"
                 id="dic" name="dic"
                 v-model="calcInfoInternal.dic">
        </div>

        <div class="form-group col-md-6">
          <label for="email">Email:</label>
          <input type="email"
                 class="form-control"
                 id="email"
                 name="email"
                 v-model="calcInfoInternal.email">
        </div>
        <div class="form-group col-md-4">
          <label for="phone">Telefon:</label>
          <input type="text" class="form-control" id="phone" name="phone" v-model="calcInfoInternal.phone">
        </div>
        <div class="form-group col-md-4">
          <label for="street">Ulice:</label>
          <input type="text" class="form-control" id="street" name="street" v-model="calcInfoInternal.street">
        </div>
        <div class="form-group col-md-4">
          <label for="city">Město:</label>
          <input type="text" class="form-control" id="city" name="city" v-model="calcInfoInternal.city">
        </div>
        <div class="form-group col-md-2">
          <label for="zipcode">PSČ:</label>
          <input type="text" class="form-control" id="zipcode" name="zipcode" v-model:="calcInfoInternal.zipcode">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculationBasicInfo",
  props: ['calculationInfo', 'calculationName'],
  emits: ['update:calculationInfo', 'update:calculationName'],
  data() {
    return {
      calcInfoInternal: this.calculationInfo,
    }
  },
  watch: {
    calcInfoInternal: {
      handler(newVal) {
        this.$emit('update:calculationInfo', newVal)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
  }
}
</script>
<style scoped>

</style>