<script>
export default {
  name: "PageNotFoundView"
}
</script>

<template>
<div class="container">
  <h1>Stránka nenalezena!!</h1>
</div>
</template>

<style scoped>

</style>