<template>
  <ModalBase :is-visible="isVisible" :save-allowed="isSaveAllowed" title="Změna materiálu:"
             :set-status-name="modalStatusName" @modal-status="logIt">
    <div class="row align-items-center" style="height: 7rem;">
      <div class="col-auto">
        <i class="bi-1-circle-fill myIcon myIconActive"></i>
      </div>
      <div class="col">
        <label for="price_list_modal" class="form-label">Ceník:</label>
        <select class="form-select" id="price_list_modal" name="price_list_modal" v-model="pricelistSelected">
          <option selected disabled value="notSelected">vyberte ceník...</option>
          <option v-for="(item, key) in priceListList" :key="key" :value="key"> {{ item.name }}</option>
        </select>
      </div>
    </div>
    <div class="row align-items-center" style="height: 7rem;">
      <div class="col-auto">
        <i class="bi-2-circle-fill myIcon" :class="thicknessDisabled ? 'myIconDisabled' : 'myIconActive'"></i>
      </div>
      <div class="col align-middle">
        <label for="thickness_modal" class="form-label">Síla materiálu:</label>
        <select class="form-select" id="thickness_modal" name="thickness_modal"
                @change="pricelistChanged" :disabled="thicknessDisabled" v-model="thicknessSelected">
          <option selected disabled value="notSelected">vyberte sílu materiálu...</option>
          <option v-for="(item, key) in thicknessList" :key="key" :value="key">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row align-items-center" style="height: 7rem;">
      <div class="col-auto">
        <i class="bi-3-circle-fill myIcon" :class="materialDisabled ? 'myIconDisabled' : 'myIconActive'"></i>
      </div>
<!--      <div class="col align-middle">
        <label for="material_modal" class="form-label">Materiál:</label>
        <select class="form-select" id="material_modal" name="material_modal"
                @change="pricelistChanged" :disabled="materialDisabled" v-model="materialSelected">
          <option selected disabled value="notSelected">vyberte materiál...</option>
          <option v-for="(item, key) in materialList" :key="key"
                  :value="key">
            {{ item.name }}
          </option>
        </select>
      </div>-->
      <div class="col align-middle">
        <AutocompleteSelect
            v-model="materialSelected"
            :options="materialListNew"
            label="Materiál:"
            placeholder="vyberte materiál..."
            id="material_select"
            :disabledSelect="materialDisabled"
        />
      </div>
    </div>
  </ModalBase>
</template>

<script>
import ModalBase from "@/components/base/ModalBase.vue";
import AutocompleteSelect from "@/components/base/AutocompleteSelect.vue";

export default {
  name: "CalculationItemMaterialModal",
  components: {
    ModalBase,
    AutocompleteSelect
  },
  props: {
    isVisible: Boolean,
  },
  emits: ['modal-status', 'selected-material'],
  data() {
    return {
      modalStatusName: 'statusModalMaterialSelect',
      pricelistSelected: 'notSelected',
      thicknessSelected: 'notSelected',
      materialSelected: 'notSelected',
      thicknessDisabled: true,
      materialDisabled: true,
      thicknessList: {},
      materialList: {},
      materialListNew: [],
    }
  },
  computed: {
    priceListList() {
      return this.$store.getters['calculation/getMaterialList']
    },
    thickness() {
      // console.log("calculated")
      return this.pricelistSelected
    },
    isSaveAllowed() {
      if (this.priceListList !== 'notSelected' && this.thicknessSelected !== 'notSelected' && this.materialSelected !== 'notSelected') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    resetForm() {
      this.pricelistSelected = 'notSelected';
      this.thicknessSelected = 'notSelected';
      this.materialSelected = 'notSelected';
      this.thicknessList = {};
      this.materialList = {};
      this.thicknessDisabled = true;
      this.materialDisabled = true;
      this.materialListNew = []
    },
    logIt(n) {
      this.$emit('modal-status', n)
      if (n === "saved") {
        this.$emit('selected-material', {
          priceListId: this.pricelistSelected,
          thicknessId: this.thicknessSelected,
          materialId: this.materialSelected
        })
      }
      if (n === 'saved' || n === 'closed') {
        this.resetForm();
      }
    }
  },
  watch: {
    pricelistSelected(newVal) {
      if (newVal !== 'notSelected') {
        this.thicknessDisabled = false;
        this.thicknessList = this.priceListList[newVal].thickness;
        this.thicknessSelected = 'notSelected';
        this.materialSelected = 'notSelected';
        this.materialDisabled = true;
        // this.thicknessDisabled = true;
      }
    }
    ,
    thicknessSelected(newVal) {
      if (newVal !== 'notSelected') {
        this.materialDisabled = false;
        this.materialList = this.priceListList[this.pricelistSelected].thickness[this.thicknessSelected].materials;
        this.materialListNew = Object.keys(this.priceListList[this.pricelistSelected].thickness[this.thicknessSelected].materials).map(key => ({value: parseInt(key, 10), label: this.priceListList[this.pricelistSelected].thickness[this.thicknessSelected].materials[key].name}))
        this.materialSelected = 'notSelected';
        // this.materialDisabled = true;
      }
    }
  }
}
</script>

<style scoped>
.myIcon {
  font-size: 3.5rem;
}

.myIconActive {
  color: forestgreen;
}

.myIconDisabled {
  color: gray;
}
</style>