<template>
  <div v-if="isVisibleInternal" class="modal fade show" id="staticBackdrop" data-bs-backdrop="static"
       data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"
       style="display:block;">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">{{ title }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                  @click="closeModal()"></button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="closeModal()">Zavřít</button>
          <button type="button" class="btn btn-success" :disabled="!saveAllowed" @click="saveModal()">Uložit změny
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ModalBase",
  props: {
    title: String,
    isVisible: Boolean,
    setStatusName: String,
    saveAllowed: Boolean,
  },
  emits: ['modal-status'],
  data() {
    return {
      isVisibleInternal: this.isVisible,
      status: 'closed'
    }
  },
  methods: {
    closeModal() {
      this.isVisibleInternal = false;
      this.status = 'closed'
      this.emitStatus(this.status)
    },
    saveModal() {
      this.isVisibleInternal = false;
      this.status = 'saved';
      this.emitStatus(this.status)
    },
    emitStatus(status) {
      this.$emit('modal-status', status)
    }
  },
  watch: {
    isVisible(newVal) {
      this.isVisibleInternal = newVal;
      if (newVal) {
        this.status = 'opened';
      } else {
        this.status = 'closed';
      }
      this.emitStatus(this.status)
    }
  },
  mounted() {
    this.emitStatus(this.status)
  }

}
</script>

<style scoped>
</style>