<template>
  <div class="container">
    <h1>Editace jednotek</h1>
  </div>
</template>

<script>
export default {
  name: "AdminUnitsView"
}
</script>

<style scoped>
</style>