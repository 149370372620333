<template>
  <div class="card myTopMargin">
    <div class="card-header">
      <h4>Stranová obrábění:</h4>
    </div>
    <div class="card-body">
      <div class="container" v-if="item">
        <SideActionSelectButton key="O"
                                :side-name="sideName"
                                side-unit-count="1"
                                :selected-actions="selectedSideActions['a']"
                                @set-value="sideActionSelected"/>
      </div>
    </div>
  </div>
</template>

<script>
import SideActionSelectButton from "@/components/items/SideActionSelectButton.vue";

export default {
  name: 'SideActions',
  components: {
    SideActionSelectButton
  },
  emits: ['selected-items'],
  props: ['sideActions'],
  data() {
    return {
      selectedSideActions: {},
      normalizedOriginSideActions: null,
      sideName: "obvod",
    }
  },
  computed: {
    item() {
      return this.$store.getters['items/getNormalizedItem']
    },
  },
  methods: {
    sideActionSelected(n) {
      for (let [actionId, actionDetail] of Object.entries(n)) {
        this.selectedSideActions[actionId] = actionDetail === undefined ? {} : actionDetail
      }
      this.$emit('selected-items', this.selectedSideActions)
    },
    resolveActionSelectedAfterStart() {
      let response = {};
      for (let [actionID, detail]  of Object.entries(this.sideActions)) {
        if (detail.sides !== {} && detail.related_to === 'side') {
          for (let sideName of Object.keys(detail.sides)) {
            response[sideName] = response[sideName] === undefined ? {[actionID]: {side: sideName}} : Object.assign(response[sideName], {[actionID]: {side: sideName}})
          }
        }
      }
      this.selectedSideActions = response
    }

  },
  created() {
    this.resolveActionSelectedAfterStart();
  },
  mounted() {
  },
}

</script>


<style scoped>

</style>