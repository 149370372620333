function IndividualItem(item) {
  if (item) {
    this.id = item.id;
    this.calculationUid = item.calculation_uid;
    this.priceListItemId = item.price_list_item_id;
    this.unitId = item.unit_id;
    this.name = item.name;
    this.description = item.description;
    this.unitCount = item.unit_count;
    this.unitPrice = item.unit_price;
  }

  Object.defineProperty(this, "totalPrice", {
    get: () => {
      return this.unitCount * this.unitPrice
    }
  })
}

export default IndividualItem;