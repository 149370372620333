<template>
  <div class="container">
    <h1>Editace ceníků</h1>
  </div>
</template>

<script>
export default {
  name: "AdminPriceListView"
}
</script>

<style scoped>
</style>