<template>
  <td>
    <input class="form-check-input me-1" type="checkbox" :id="'piece-action-' + item.id" :value="item.id"
           @change="toggleSelected" :checked="item.checked">
  </td>
  <td>
    <input type="number" class="form-control" :id="'piece-action-count-' + item.id" :disabled="isDisabled"
           v-model="value">
  </td>
  <td> {{ item.unit.name }}</td>
  <td> {{ item.name }}</td>
</template>
<script>
export default {
  name: "PieceActionRow",
  emits: ['set-value', 'disable'],
  props: ['item'],
  data() {
    return {
      isDisabled: true,
      value: null
    }
  },
  methods: {
    toggleSelected(event) {
      if (event.target.checked) {
        this.isDisabled = false;
        this.value = 1
      } else {
        this.isDisabled = true;
        this.value = null;
        this.$emit('disable', this.item.id);
      }
    }
  },
  watch: {
    value(newVal) {
      if (newVal) {
        let content = {unit_count: newVal, related_to: 'piece', sides: null}
        this.$emit('set-value', {id: this.item.id, value: content});
      }
    }
  },
  created() {
    this.isDisabled = !this.item.checked
    this.value = this.item.unit_count
  }
}
</script>
<style scoped>

</style>