<template>
  <div class="my-5" v-html="calculatedData"></div>
  <div class="row justify-content-end myTopMargin">
    <div class="col-auto">
      <button type="submit" class="btn btn-danger mx-1" @click="cancelButton">Zrušit</button>
      <button type="submit" class="btn btn-success mx-1">Uložit</button>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "CalculationCalculateTable",
  props: ['calculation', 'calculatedData'],
  methods: {
    cancelButton() {
      router.push({
        name: 'home'
      })
    },
  }
}
</script>

<style scoped>
</style>