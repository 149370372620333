import "bootstrap/dist/css/bootstrap.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const myApp = createApp(App);

// setup global properties
myApp.config.globalProperties.URL_API_BASE = process.env.VUE_APP_API_HOST;
myApp.config.globalProperties.URL_BASE = process.env.VUE_APP_URL_BASE;

myApp.use(store).use(router).mount('#app');

import "bootstrap/dist/js/bootstrap.js"
