// constructor for Calculation Item object
// create CalculationItem object from DB object item
function CalculationItem(item) {
  let itemOrigin = item;
  this.id = item.id;
  this.calculationUid = item.calculation_uid;
  this.shapeId = item.shapes_id;
  this.name = item.name;
  this.priceListItem = item.pricelist_items_id;
  this.dimensions = item.dimensions;
  this.area = item.area;
  this.areaWithCutoff = item.area_with_cutoff;
  this.weight = item.weight;
  this.orientation = item.orientation;
  this.directional = item.directional;
  this.surface = item.surface;
  this.impregnation = item.impregnation;
  this.calculationTransportationsId = item.calculation_transportations_id;
  this.calculationAssemblyId = item.calculation_assembly_id;
  this.perimetr = item.perimeter;
  this.perimeter_as_side = item.perimeter_as_side;
  this.manipulation_koef = item.manipulation_koef;
  this.actions = null;

  // action resolver
  let actionHashTable = {}
  if (item.actions) {
    item.actions.forEach((action) => {
      if (action.action_group !== 'rezani') {
        actionHashTable[action.actions_id] = {
          related_to: action.related_to,
          unit_count: action.unit_count,
          sides: action.sides
        }
      }
    })
    this.actions = actionHashTable
  } else {
    this.actions = null;
  }

  Object.defineProperty(this, 'itemOrigin', {
    get: () => {
      return itemOrigin
    }
  })
}

export default CalculationItem;