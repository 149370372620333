<template>
  <div class="container" style="margin-top: 1em; margin-bottom: 1em;">
    <h1 class="text-success">This is an about page</h1>

  </div>
</template>

<script>
export default {
  name: 'AboutView',
  components: {
  },
  data() {
    return {
      selectedValue: null,
      materialList: null,
      options: [
        {value: '1', label: 'Hovno 1'},
        {value: '2', label: 'Prdel 2'},
        {value: '3', label: 'Sračka 3'},
      ],
    }
  },
  mounted() {
  }
}
</script>