<template>
  <section>
    <div class="container py-5" v-if="showDialog">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow" style="border-radius: 1rem;">
            <div class="card-body p-5 text-center">
              <form @submit.prevent="submitChangePassword">
                <h3 class="mb-5">Změna hesla</h3>
                <div class="form-floating mb-4">
                  <input type="password" id="oldPassword" class="form-control form-control-lg" v-model="oldPassword"/>
                  <label class="form-label" for="oldPassword">Původní heslo</label>
                </div>

                <div class="form-floating mb-4">
                  <input type="password" id="newPassword" class="form-control form-control-lg"
                         v-model="newPassword"/>
                  <label class="form-label" for="newPassword">Nové heslo</label>
                </div>
                <div class="form-floating mb-4">
                  <input type="password" id="newPasswordCheck" class="form-control form-control-lg" :class="errorClass"
                         v-model="newPasswordCheck"/>
                  <label class="form-label" for="newPasswordCheck">Potvrď nové heslo</label>
                </div>
                <div v-if="errorShow" class="row my-3">
                  <p class="text-danger"> {{ errorMessage }} </p>
                </div>
                <div class="row d-flex justify-content-end">
                  <div class="col-auto">
                    <button class="btn btn-primary btn-block" :class="disableSubmitClass" type="submit">Změna hesla
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container py-5" v-if="showConfirmation">
      <div class="row d-flex justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6 col-xl-5">
          <div class="card shadow" style="border-radius: 1rem; background-color: lightgreen;">
            <div class="card-body py-5 text-center">
              <h3 class="mb-5">Heslo bylo úspěšné změněno!</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import myApi from "@/api/api";
import router from "@/router";

export default {
  name: "PasswordChangeForm",
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordCheck: "",
      errorShow: false,
      errorMessage: "",
      showPassword: false,
      disableSubmit: false,
      showDialog: true,
      showConfirmation: false,
    }
  },
  computed: {
    buttonLabel() {
      return (this.showPassword) ? "Hide" : "Show";
    },
    errorClass() {
      if (this.newPassword !== this.newPasswordCheck) {
        return "wrong-input"
      } else {
        return ""
      }
    },
    disableSubmitClass() {
      return this.disableSubmit ? "disabled" : ""
    }
  },
  methods: {
    async submitChangePassword() {
      this.errorShow = false;
      if (!this.newPassword || (typeof this.newPassword === 'string' && this.newPassword === 0)) {
        this.errorMessage = "Nové heslo nesmí být prázdné";
        this.errorShow = true;
        return
      }

      if (this.newPassword !== this.newPasswordCheck) {
        this.errorShow = true;
        this.errorMessage = "Nová hesla se neshodují."
        return
      }
      const response = await myApi.changePassword(this.oldPassword, this.newPassword);
      if (!response.status) {
        this.errorShow = true;
        this.errorMessage = response.error;
      }
      this.showDialog = false;
      this.showConfirmation = true;
      setTimeout(() => {
        router.push('/');
      }, 2000)
    },
  }
}
</script>


<style scoped>
.wrong-input {
  border-color: #ff9999; /* Světle červená barva borderu */
  box-shadow: 0 0 5px rgba(255, 153, 153, 0.5); /* Světle červený stín */
  outline: none; /* Odstranění defaultního outline */
}

</style>