<template>
  <div class="card myTopMargin">
    <div class="card-header">
      <h4>Rozměry (mm):</h4>
    </div>
    <div class="card-body">
      <div class="row gy-3">
        <div class="col col-md-7">
          <div class="row gy-3">
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text" id="side_a_header">Hrana A</span>
                <input type="number" class="form-control" step="1" min="1" aria-label="side_a"
                       aria-describedby="side_a_header" placeholder="Zadejte rozměry" id="side_a"
                       name="a"
                       v-model="sideAValue"
                       @focus="highlightSide('A')"
                       @blur="removeHighlight('A')"
                       required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text" id="side_b_header">Hrana B</span>
                <input type="number" class="form-control" step="1" min="1" aria-label="side_b"
                       aria-describedby="side_b_header" placeholder="Zadejte rozměry" id="side_b"
                       name="b"
                       v-model="sideBValue"
                       @focus="highlightSide('B')"
                       @blur="removeHighlight('B')"
                       required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text" id="side_c_header">Hrana C</span>
                <input type="number" class="form-control" step="1" min="1" aria-label="side_c"
                       aria-describedby="side_c_header" placeholder="Zadejte rozměry" id="side_c"
                       name="c"
                       v-model="sideCValue"
                       disabled
                       required>
              </div>
            </div>
            <div class="col-md-6">
              <div class="input-group">
                <span class="input-group-text" id="side_d_header">Hrana D</span>
                <input type="number" class="form-control" step="1" min="1" aria-label="side_d"
                       aria-describedby="side_d_header" placeholder="Zadejte rozměry" id="side_d"
                       name="d"
                       v-model="sideDValue"
                       disabled
                       required>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex col-md-5 bg-light order-first order-md-last justify-content-center">
          <div ref="svgContainer" v-html="svgContent" class="d-flex image_responsive"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ctverec from "@/assets/quard_item.svg"

export default {
  name: "CalculationItemDimensions",
  emits: ['dimension-change'],
  props: ['itemSides'],
  data() {
    return {
      svgContent: '',
      itemSidesInt: this.itemSides,
      sideAValue: null,
      sideBValue: null,
      sideCValue: null,
      sideDValue: null
    }
  },
  methods: {
     async loadSVG() {
      const response = await axios.get(ctverec);
      this.svgContent = response.data;
    },
    highlightSide(side) {
      this.removeHighlight();
      const textElement = this.$refs.svgContainer.querySelector(`#text${side}`);
      if (textElement) {
        textElement.setAttribute('style', 'font-weight: bold; fill: red; font-size: 2em;');
      }
    },
    removeHighlight() {
      const highlightedElements = this.$refs.svgContainer.querySelectorAll('text[style]');
      highlightedElements.forEach(element => {
        element.removeAttribute('style');
      });
    }
  },
  created() {
    this.sideAValue = this.itemSides['a']
    this.sideBValue = this.itemSides['b']
    this.sideCValue = this.itemSides['c']
    this.sideDValue = this.itemSides['d']
  },
  watch: {
    sideAValue(newValue) {
      this.sideCValue = newValue;
      this.itemSidesInt['a'] = newValue;
      this.itemSidesInt['c'] = newValue
      this.$emit('dimension-change', this.itemSidesInt);
    },
    sideBValue(newValue) {
      this.sideDValue = newValue;
      this.itemSidesInt['b'] = newValue;
      this.itemSidesInt['d'] = newValue
      this.$emit('dimension-change', this.itemSidesInt);
    }
  },
    async mounted() {
    await this.loadSVG();
  }
}
</script>

<style scoped>

.image_responsive {
    max-width: 100%;
    height: auto; /* zachování proporcí */
    display: block;
}
</style>