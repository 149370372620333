<template>
  <div class="col-12 col-sm-12 col-md-6 col-lg-4">
    <div class="card m-2">
      <div class="card-header">
        <h5> {{ itemDetail.name }}</h5>
      </div>
      <div class="card-body">
        <p><strong>Materiál:</strong> {{ itemName }}</p>
        <p><strong>Váha:</strong> {{ itemDetail.weight }} Kg</p>
        <p><strong>Plocha:</strong> {{ itemDetail.area }} m<sup>2</sup></p>
      </div>
      <div class="card-footer">
        <a class="btn btn-primary mx-1" role="button" @click="editItem">Editovat</a>
        <a class="btn btn-warning mx-1" role="button" @click="copyItem">Vytvoř kopii</a>
        <a class="btn btn-danger mx-1" role="button" @click="deleteItem">Smaž</a>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "CalculationItemDetailList",
  emits: ['item-update'],
  props: ['itemDetail'],
  computed: {
    urlItem() {
      return 'cid=' + this.itemDetail.calculation_uid + '&item=' + this.itemDetail.id + '&update=true'
    },
    itemName() {
      return `${this.itemDetail.itemOrigin.price_list_item.name}, ${this.itemDetail.itemOrigin.price_list_item.surface}`
    }
  },
  methods: {
    editItem() {
      let calculation = this.$store.getters['calculation/getCalculation']
      this.$store.dispatch('calculation/updateCalculation', calculation)
          .then(() => {
            router.push({
              name: 'calculationItemEdit',
              query: {cid: this.itemDetail.calculationUid, item: this.itemDetail.id, update: true}
            })
          })
    },
    copyItem() {
      let calculation = this.$store.getters['calculation/getCalculation']
      this.$store.dispatch('calculation/updateCalculation', calculation)
          .then(() => {
            this.$store.dispatch('items/copyItem', {item: this.itemDetail.id})
                .then(() => this.$emit('item-update'))
          })
    },
    deleteItem() {
      let calculation = this.$store.getters['calculation/getCalculation']
      this.$store.dispatch('calculation/updateCalculation', calculation)
          .then(() => {
            this.$store.dispatch('items/deleteItem', {item: this.itemDetail.id})
                .then(() => this.$emit('item-update'))
          })
      // this.$store.dispatch('calculation/setCalculationById', this.itemDetail.calculation_uid)
    }
  }
}
</script>

<style scoped>
.myCartMargin {
  margin: .5em;
}
</style>