function Transportation(tr) {
  this.id = tr.id;
  this.calculationUid = tr.calculation_uid;
  this.floorWithLift = tr.floor_with_lift;
  this.floorWithoutLift = tr.floor_without_lift;
  this.distanceFromParking = tr.distance_from_parking;
  this.distance = tr.distance;
  this.itemList = [];
}

export default Transportation;