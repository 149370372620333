<template>
  <div class="container">
    <password-change-form />
  </div>
</template>

<script>
import passwordChangeForm from "@/components/auth/PasswordChangeForm.vue";

export default {
  name: "PasswordChangeView",
  components: {
    passwordChangeForm
  }
}
</script>



<style scoped>

</style>