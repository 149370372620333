<template>
  <div class="row justify-content-left myTopMargin moje-row-border">
    <div class="col">
      <div class="dropdown">
        <button class="btn btn-outline-dark dropdown-toggle"
                type="button"
                id="multiSelectDropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false">
          Strana {{ sideName.toUpperCase() }}
        </button>
        <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="multiSelectDropdown">
          <li v-for="item in dropSideActions" :key="item.id" class="moje-li">
            <label>
              <input type="checkbox" :id="'side-action-' + sideName + '-' + item.id"
                     @change="toggleSiteSiteSelectedActions"
                     :checked="resolveChecked(item.id, sideName)"
                     :value="item.id">
              {{ item.name }}
            </label>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-auto">
      <button class="btn btn-dark btn-sm moje-button-margin disabled" v-for="(content, value)  in selectedActionsInternal[sideName]"
              :key="value">
        {{ actionIdNames[value] }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideActionSelectButton",
  props: ['sideName', 'sideUnitCount', 'selectedActions'],
  emits: ['set-value'],
  data() {
    return {
      selectedActionsInternal: {}
    }
  },
  computed: {
    dropSideActions() {
      let response = this.$store.getters['items/getCalculationItemActions'];
      return response === null ? null : response.side;
    },
    actionIdNames() {
      let response = {}
      if (this.dropSideActions) {
        this.dropSideActions.forEach((element) => {
          response[element.id] = element.name
        })
      }
      return response
    }
  },
  methods: {
    toggleSiteSiteSelectedActions(event) {
      const isChecked = event.target.checked
      const itemId = event.target.value
      let content = {[itemId]:{side: this.sideName}}
      // let content = parseInt(itemId)
      if (isChecked) {
        if (this.selectedActionsInternal[this.sideName] !== undefined) {
          Object.assign(this.selectedActionsInternal[this.sideName], content);
        } else {
          this.selectedActionsInternal[this.sideName] = content
        }
      } else {
        delete this.selectedActionsInternal[this.sideName][itemId]
      }
    },
    resolveChecked(actionId, sideName) {
      if (this.selectedActionsInternal[sideName] !== undefined) {
        return Object.keys(this.selectedActionsInternal[sideName]).includes(actionId.toString())
      } else {
        return false
      }
    }
  },
  watch: {
    selectedActionsInternal: {
      handler(newVal) {
        this.$emit('set-value', newVal)
      },
      deep: true,
      immediate: true
    },

  },
  created() {
    this.selectedActionsInternal[this.sideName] = this.selectedActions
  }
}
</script>

<style scoped>
.dropdown-menu {
  padding: .7em;
  padding-left: 1.2em;
  padding-right: 1.2em;
}

.moje-li {
  margin-bottom: .3em;
}

.moje-button-margin {
  margin: .2em;
}

.moje-row-border {
  border: #ccc 1px solid;
  border-radius: .5em;
  padding: .5em;
}
</style>