<template>
  <IndividualItemEditModal/>
  <div class="row my-3" v-if="showList">
    <h4>Seznam individuálních položek</h4>
    <table class="table">
      <thead class="table-head">
      <tr>
        <th>Název</th>
        <th class="text-end">Počet</th>
        <th class="text-end">Jednotková cena</th>
        <th class="text-end">Celková cena</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr class="align-middle" v-for="(item, key) in individualItems" :key="key">
        <td class="col-6"> {{ item.name }}</td>
        <td class="col-1 text-end"> {{ formatFloat(item.unitCount, 4) }}</td>
        <td class="col-2 text-end"> {{ formatFloat(item.unitPrice, 2) }} Kč</td>
        <td class="col-2 text-end"> {{ formatFloat(item.totalPrice, 2) }} Kč</td>
        <td class="col-1 text-end">
          <!--          <a class="btn btn-warning mx-1" role="button" @click="editItem(item.id)">Editovat</a>-->
          <div class="m-2">
            <i class="bi-trash-fill myIcon" @click="deleteItem(item.id)"></i>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import myApi from "@/api/api";
import IndividualItemEditModal from "@/components/calculation/IndividualItemEditModal.vue";
import {formatFloat} from "@/common/functions"

export default {
  name: "IndividualItemInsert",
  props: ["cid"],
  components: {
    IndividualItemEditModal
  },
  data() {
    return {}
  },
  computed: {
    individualItems() {
      return this.$store.getters["calculation/getIndividualItems"]
    },
    showList() {
      return !(!this.individualItems || this.individualItems.length === 0);
    }
  },
  methods: {
    async deleteItem(itemId) {
      const response = await myApi.deleteIndividualItem(itemId)
      if (response) {
        this.$store.dispatch("calculation/individualItemList", this.cid);
      }
    },
    async editItem(itemId) {
      console.log(formatFloat(10.2564, 2))
      console.log("executed, item ID: " + itemId)
    },
    formatFloat
  },
  async created() {
    await this.$store.dispatch("calculation/individualItemList", this.cid)
  }
}
</script>


<style scoped>
.myIcon:hover {
  color: darkred;
}
</style>