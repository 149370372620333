<template>
  <div class="container">
    <!--      <input type="hidden" id="cid" name="cid" :value="cid">-->
    <!--      <input type="hidden" id="item_id" name="item_id" :value="item_id">-->
    <!--      <input v-if="item" type="hidden" id="material_origin_id" name="material_origin_id"-->
    <!--             :value="item.pricelist_items_id">-->
    <!--      <input v-if="item" type="hidden" id="shape_id" name="shape_id" :value="item.shapes_id">-->
    <CalculationItemMaterialSelector v-if="item && !!this.priceListItemOrigin"
                                     :price-list-item-origin="priceListItemOrigin"
                                     :cid="cid"
                                     @selected-material="updateItemMaterial"/>
    <CalculationItemName v-if="item"
                         :name="item.name"
                         @update-name="updateItemName"/>
    <CalculationItemDimensions v-if="item && dimensions && item.shapeId !== 1"
                               :item-sides="dimensions" :shape="item.shapeId"
                               @dimension-change="updateDimensions"/>
    <CalculationItemDimensionsSquare v-if="item && dimensions && item.shapeId === 1"
                                     :item-sides="dimensions"
                                     @dimension-change="updateDimensions"/>
    <CalculationItemImpregnation :key="componentKey"
                                 v-if="item && !!this.priceListItemOrigin"
                                 :item-actions="item.actions"
                                 @selected-items="setSelectedImpregnation"/>
    <SideActions :key="componentKey"
                 v-if="item && !!this.priceListItemOrigin && !item.perimeter_as_side"
                 :side-actions="item.actions"
                 @selected-items="setSelectedSidesActions"/>
    <SidePerimeterActions :key="componentKey"
                          v-if="item && !!this.priceListItemOrigin && item.perimeter_as_side"
                          :side-actions="item.actions"
                          @selected-items="setSelectedSidesPerimeterAction"/>
    <PieceActions :key="componentKey"
                  v-if="item && !!this.priceListItemOrigin"
                  :material-id="this.priceListItemOrigin.material"
                  :thickness-id="this.priceListItemOrigin.thickness"
                  :item-actions="item.itemOrigin.actions"
                  @selected-items="setSelectedPieceActions"/>
    <div class="row justify-content-end myTopMargin">
      <div class="col-auto">
        <button type="submit" class="btn btn-danger mx-1" @click="chancelButton">Zrušit</button>
        <button type="submit" class="btn btn-success mx-1" @click="saveItem">Uložit</button>
      </div>
    </div>
  </div>
</template>

<script>
import CalculationItemDimensions from "@/components/items/CalculationItemDimensions.vue";
import CalculationItemDimensionsSquare from "@/components/items/CalculationItemDimensionsSquare.vue";
import CalculationItemMaterialSelector from "@/components/items/CalculationItemMaterialSelector.vue";
import CalculationItemImpregnation from "@/components/items/CalculationItemImpregnation.vue";
import CalculationItemName from "@/components/items/CalculationItemName.vue";
import PieceActions from "@/components/items/PieceActions.vue";
import SideActions from "@/components/items/SideActions.vue";
import SidePerimeterActions from "@/components/items/SidePerimeterActions.vue";
import router from "@/router";

export default {
  name: "CalculationItemEditView",
  components: {
    CalculationItemDimensions,
    CalculationItemDimensionsSquare,
    CalculationItemMaterialSelector,
    CalculationItemImpregnation,
    CalculationItemName,
    SideActions,
    SidePerimeterActions,
    PieceActions,
  },
  props: ['cid', 'item_id', 'do_update'],
  data() {
    return {
      selectedPieceActions: {},
      selectedSidesActions: {},
      selectedSidesPerimeterActions: {},
      priceListItemOrigin: {
        pricelist: 1,
        thickness: 1,
        material: 1
      },
      selectedImpregnationAction: {},
      item: null,
      needItemUpdate: true,
      componentKey: 0
    }
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    updateItemMaterial(n) {
      this.$store.dispatch('items/updateItemMaterial', {item_id: this.item_id, price_list_item_id: n.materialId})
          .then(() => {
            this.needItemUpdate = true
          })
      // router.push({name: 'calculationEdit', query: {cid: this.cid}});
      // router.push({name: 'calculationItemEdit', query: {cid: this.cid, item: this.item_id, update: true}});
    },
    updateDimensions(n) {
      this.$store.commit('items/updateItemDimensions', n)
    },
    updateItemName(n) {
      if (this.item) {
        this.$store.commit('items/updateItemName', n)
      }
    },
    saveItem() {
      const actionNormalized = {};
      const dimensions = this.$store.getters['items/getItemDimensions']
      // normalizace obrábění pro strany a následné sloučení s
      // obrábením pro dílce - nezapomenout na impregnaci
      for (let [sideName, value] of Object.entries(this.selectedSidesActions)) {
        for (let actionId of Object.keys(value)) {
          if (actionNormalized[actionId] === undefined) {
            actionNormalized[actionId] = {
              unit_count: dimensions[sideName],
              related_to: 'side',
              sides: {[sideName]: dimensions[sideName]}
            };
          } else {
            actionNormalized[actionId].unit_count += dimensions[sideName];
            Object.assign(actionNormalized[actionId].sides, {[sideName]: dimensions[sideName]});
          }
        }
      }
      Object.assign(actionNormalized, this.selectedSidesPerimeterActions)
      Object.assign(actionNormalized, this.selectedPieceActions)
      Object.assign(actionNormalized, this.selectedImpregnationAction)
      this.$store.commit('items/updateItemActions', actionNormalized)
      this.$store.dispatch('items/updateItem', this.$store.getters['items/getNormalizedItem'])
          .then(() => router.push({name: 'calculationEdit', query: {cid: this.cid}}))
    },
    chancelButton() {
      // this.$store.commit('items/setCalculationItem', null)
      router.push({name: 'calculationEdit', query: {cid: this.cid}})
    },
    setSelectedPieceActions(n) {
      this.selectedPieceActions = n;
    },
    setSelectedSidesActions(n) {
      this.selectedSidesActions = n;
    },
    setSelectedImpregnation(n) {
      this.selectedImpregnationAction = n;
    },
    setSelectedSidesPerimeterAction(n) {
      let actions = n.obvod;
      const response = {};
      if (actions) {
        for (let key of Object.keys(actions)) {
          Object.assign(response, {
            [key]: {
              related_to: "side",
              sides: {a: 1},
              unit_count: 1
            }
          })
        }
      }
      this.selectedSidesPerimeterActions = response;
    },
    createPriceListItemOrigin() {
      let response = null

      if (this.item !== null) {
        // console.log(this.item)
        response = {
          pricelist: this.item.itemOrigin.price_list_item.price_list_id,
          thickness: this.item.itemOrigin.price_list_item.thickness_id,
          material: this.item.itemOrigin.price_list_item.id
        };
        this.$store.dispatch('items/loadItemActionsNormalized', {
          'material_id': this.item.itemOrigin.price_list_item.material_id,
          'thickness_id': this.item.itemOrigin.price_list_item.thickness_id,
          'exclude_action_groups': 'rezani'
        });
      }
      return response
    },
    itemUpdate() {
      this.$store.dispatch('items/loadCalculationItem', this.item_id)
          .then((result) => {
            if (result) {
              this.item = this.$store.getters['items/getNormalizedItem'];
              this.priceListItemOrigin = this.createPriceListItemOrigin()
              this.forceRerender()
              this.needItemUpdate = false
            }
          })
    }
  },
  computed: {
    dimensions() {
      if (this.item) {
        return this.item.dimensions
      } else {
        return null
      }
    }
  },
  watch: {
    needItemUpdate(newValue) {
      if (newValue) {
        this.itemUpdate()
      }
    }
  },
  created() {
    // this.getCalculationItem(this.item_id)
    this.$store.dispatch('items/loadCalculationItem', this.item_id)
        .then((result) => {
          if (result) {
            this.itemUpdate()
          }
        });
  }
}
</script>

<style scoped>
</style>