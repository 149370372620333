<template>
  <NavBar/>
  <router-view/>
  <TosFooter/>
</template>

<script>
import NavBar from "@/components/base/NavBar.vue";
import TosFooter from "@/components/base/TosFooter.vue";
import store from "@/store";

export default {
  components: {
    NavBar,
    TosFooter
  },
  created() {
    store.dispatch('autoLoginUser').then((response) => {
      if (response) {
        this.$store.dispatch('items/loadItemShapes');
        this.$store.dispatch('calculation/loadMaterialList')
      }
    })
  },
  mounted() {
    const isAuth = this.$store.getters['getIsAuthenticated']
    if (!this.$store.getters['items/getCalculationItemShapes'] && isAuth) {
      this.$store.dispatch('items/loadItemShapes');
    }
    if (!this.$store.getters['calculation/getMaterialList'] && isAuth) {
      this.$store.dispatch('items/loadItemShapes');
    }
  }
}
</script>

<style>
* {
  font-size: 13px;
}

.navbar {
  min-height: 5em;
}

.myTopMargin {
  margin-top: 2em;
}
</style>
