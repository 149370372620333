<template>
  <div class="autocomplete-select">
    <label :for="id" class="form-label">{{ label }}</label>
    <div class="custom-select-wrapper">
      <input
        :id="id"
        type="text"
        class="form-control"
        v-model="searchQuery"
        @click="showOptions = true"
        @keydown.esc="pressEsc"
        @input="filterOptions"
        @blur="handleBlur"
        :placeholder="placeholder"
        autocomplete="off"
        :disabled="disabledSelect"
      />
      <span class="custom-arrow"></span>
    </div>
    <div v-if="showOptions && filteredOptions.length" class="options-container">
      <ul class="list-group mt-1 options-list list-group-flush">
        <li
          class="list-group-item"
          v-for="option in filteredOptions"
          :key="option.value"
          @mousedown.prevent="selectOption(option)"
        >
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutocompleteSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    disabledSelect: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, null],
      default: null,
    },
    label: {
      type: String,
      default: 'Select an option',
    },
    placeholder: {
      type: String,
      default: 'Search...',
    },
    id: {
      type: String,
      default: 'autocomplete-select',
    },
  },
  data() {
    return {
      searchQuery: '',
      showOptions: false,
      filteredOptions: this.options,
      selectedOption: '',
    };
  },
  watch: {
    options(newOptions) {
      this.filteredOptions = newOptions;
      this.filterOptions();
    },
    modelValue(newValue) {
      const selectedOption = this.options.find(option => option.value === newValue);
      if (selectedOption) {
        this.searchQuery = selectedOption.label;
      }
    },
  },
  methods: {
    filterOptions() {
      const query = this.searchQuery.toLowerCase();
      this.filteredOptions = this.options.filter(option =>
        option.label.toLowerCase().includes(query)
      );
    },
    selectOption(option) {
      this.searchQuery = option.label;
      this.$emit('update:modelValue', option.value);
      this.showOptions = false;
      this.filteredOptions = this.options;
      this.selectedOption = option.label;
    },
    handleBlur() {
      // Delay hiding the options to allow for click event to register
      setTimeout(() => {
        this.showOptions = false;
      }, 200);
    },
    pressEsc() {
      if (this.showOptions) {
        this.showOptions = false;
        this.searchQuery = this.selectedOption;
        this.filteredOptions = this.options;
      }
    },
  },
};
</script>

<style scoped>
.autocomplete-select {
  position: relative;

  .custom-select-wrapper {
    position: relative;
  }

  .custom-select-wrapper input {
    padding-right: 30px; /* Space for the arrow */
  }

  .custom-arrow {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; /* Prevent the arrow from blocking clicks */
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
  }

  .options-container {
    position: absolute;
    width: 100%;
    max-height: 315px; /* Max height for the scrollable area */
    overflow-y: hidden; /* Enable vertical scrolling */
    border: 1px solid #ccc; /* Border around the scrollable area */
    padding: 3px; /* Padding inside the border */
    background: #fff; /* Ensure background is white */
    z-index: 1000;
  }

  .list-group-item:hover {
    background-color: #f0f0f0; /* Custom hover background color */
  }

  .options-list {
    max-height: 315px; /* Adjust as needed for your design */
    overflow-y: auto; /* Enable vertical scrolling */
    margin: 0; /* Remove default margin */
  }
}
</style>